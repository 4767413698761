@import url('https://fonts.googleapis.com/css2?family=PT+Sans&display=swap');

.sidebar {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #ADD8E6;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
}

.sidebar a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.sidebar a:hover {
  color: #f1f1f1;
}

.sidebar .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

.openbtn {
  font-size: 20px;
  cursor: pointer;
  background-color: #ADD8E6;
  color: black;
  padding: 10px 15px;
  border: none;
}

.openbtn:hover {
  background-color: #444;
}

html, body {
  height: 100%;
}

a {
  text-decoration: none;
}

.Heading {
  color: blue;
}

#root {
  margin: 0;
  padding: 0;
  color: black;
  font-family: 'PT Sans', sans-serif;
}

.Header {
  display: grid;
  grid-template-rows: 100%;
  grid-template-columns: 25% 12.5% 12.5% 12.5% 12.5% 25%;
  text-align: center;
  visibility: visible;
}

.HeaderSmall {
  visibility: hidden;
}

.SkillsHeader .ProjectsHeader .ExperienceHeader .EducationHeader .AboutMeHeader{
  grid-row: 1;
}

.SkillsHeader {
  grid-column: 2;
}

.ProjectsHeader {
  grid-column: 3;
}

.ExperienceHeader {
  grid-column: 4;
}

.EducationHeader {
  grid-column: 5;
}

.Footer {
  text-align: center;
  background-color: #ADD8E6;
  color: black;
  margin: 0;
  padding: 0;
}

.Welcome, .Skills, .Projects, .Experience, .Education{
  background-color: #ADD8E6;
  padding: 1px 5px;
}

.Welcome{
  margin: 0;
  height: 60vh;
}

#name {
  font-size: 400%;
}

.introDetails {
  font-size: 200%;
}

.allIntro {
  text-align: center;
  padding-top: 50px;
}

#name , .introDetails {
  color:white;
  text-shadow: 0 0 3px black;
}



.Skills {
  display: grid;
  grid-template-rows: 15% 35% 35% 15%;
  grid-template-columns: 60% 40%;
}

.Skills > .Heading {
  grid-row: 1 ;
  grid-column: 1 / span 2;
  text-align: center;
}
.languagegrid, .webgrid, .databasegrid, .workflowgrid {
  text-align: center;
}

.languagegrid {
  grid-row: 2;
  grid-column: 1;
}

.description > p {
  padding: 0;
  margin: 0;
}


#project1, #project2, #project3, #project4, #project5, #project6, #exp1, #exp2, #exp3, #exp4, #educ1, #educ2, #educ3{
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: auto;
}

.name {
  grid-row: 1;
  grid-column: 1;
}

.date {
  grid-row: 2;
  grid-column: 2;
  text-align: end;
}

.description {
  grid-row: 3;
  grid-column: 1 / span 2;
  margin-left: 25px;
}

.title {
  grid-row: 2;
  grid-column: 1;
  padding: 0;
  margin: 0;
}

.links > a {
  margin-left: 25px;
}

.languagegrid, .webgrid {
  margin-bottom: 8em;
}

.webgrid, .workflowgrid {
  padding-right: 25em;
}

@media only screen and (max-width: 1300px) {
  .allIntro {
    padding: 150px 5px;
  }

  #name {
    font-size: 300%;
  }

  .introDetails {
    font-size: 160%;
  }
}
@media only screen and (max-width: 1200px) {
  .allIntro {
    padding: 150px 5px;
  }

  #name {
    font-size: 250%;
  }

  .introDetails {
    font-size: 120%;
  }
}

@media only screen and (max-width: 1000px) {
  .allIntro {
    padding: 100px 5px;
  }

  #name {
    font-size: 150%;
  }

  .introDetails {
    font-size: 100%;
  }
}

@media only screen and (max-width: 800px) {
  .allIntro {
    padding:50px 10px;
  }
}

@media only screen and (max-width: 600px) {
  .allIntro {
    padding: 0 5px;
  }
}

@media only screen and (max-width: 400px) {
  .allIntro {
    padding: 0 5px;
  }

  #name {
    font-size: 90%;
  }

  .introDetails {
    font-size: 80%;
  }

  .Welcome {
    height: 15em;
    width: 105%;
  }

  .allIntro {
    padding-top: 30px;
  }

  .languagegrid, .webgrid {
    margin-bottom: 8em;
  }

  .webgrid, .workflowgrid {
    padding-right: 0;
  }

  .Skills > p {
    padding-right: 5em;
    margin-right: 5em;
  }
}